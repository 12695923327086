// App.js
import { HashRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./context/AuthContext";

import "./index.css";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import ProtectedRoute from "./ProtectedRoute";
import PayPage from "./pages/PayPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import SuccessPage from "./pages/SuccessPage";
import FailurePage from "./pages/FailurePage";
import Navbar from "./components/Navbar";
import PanelPage from "./pages/PanelPage";
import PropertyDetail from "./pages/PropertyPage";
import PropertiesPage from "./pages/PropertiesPage";
const theme = createTheme({
  palette: {
    primary: {
      main: "#ebe4d2 ",
    },
    secondary: {
      main: "#357DED",
    },
    terceary: {
      main: "#357DED",
    },
    mp: {
      main: "#00A3E0 ",
    },
    white: { main: "#fff" },
    backg: { main: "#F4FEC1" },
  },
  typography: {
    fontFamily: '"Raleway", Arial, sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1880,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <HashRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/pay" element={<PayPage />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/failure" element={<FailurePage />} />
            <Route path="/property/:id" element={<PropertyDetail />} />
            <Route path="/properties" element={<PropertiesPage />} />

            <Route element={<ProtectedRoute />}>
              <Route path="/panel" element={<PanelPage />} />
            </Route>
          </Routes>
          <Footer />
        </HashRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
