import React, { useState, useEffect } from "react";
import PromotionalCarousel from "../components/PromotionsCarousel";
import PromotionalCarousel2 from "../components/PromotionsCarousel2";
import { Box, Typography, Button, Grid } from "@mui/material"; // Importa Typography
import ContactUs from "../components/ContactUs";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();
  const handleScroll = () => {
    const element = document.getElementById("cu");
    if (element) {
      // Primero realiza el scroll hacia el elemento
      element.scrollIntoView({ behavior: "smooth" });

      // Luego ajusta la posición 120px más arriba
      setTimeout(() => {
        window.scrollBy(0, -120);
      }, 300); // 300 ms para asegurar que el scroll se complete antes de aplicar el ajuste
    }
  };
  return (
    <Box
      sx={{
        marginTop: {
          xs: "80px",
          sm: "80px",
          mb: "80px",
          lg: "80px",
          xl: "120px",
        },
      }}
    >
      <PromotionalCarousel></PromotionalCarousel>
      <Box
        sx={{
          backgroundColor: "#E8FCC2",
          textAlign: "left",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "1vh",
          paddingRight: "1vh",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "1.3rem",
              sm: "1rem",
              mb: "1.5rem",
              lg: "1.5rem",
              xl: "2rem",
            },
            padding: "1vh",
            cursor: "pointer", // Añadido para que parezca un botón
            "&:hover": {
              textDecoration: "underline", // Subraya el texto en hover
            },
          }}
        >
          Featured properties
        </Typography>

        <Button
          sx={{
            color: "black",
            fontSize: {
              xs: "0.7rem",
              sm: "1rem",
              mb: "1rem",
              lg: "1rem",
              xl: "1.5rem",
            },
            cursor: "pointer", // Añadido para que parezca un botón
            "&:hover": {
              textDecoration: "underline", // Subraya el texto en hover
            },
          }}
          onClick={() => navigate("/properties")}
        >
          Browse all {">"}
        </Button>
      </Box>

      <Grid container>
        <Grid xs={12} sm={12} item>
          <Box
            sx={{
              paddingRight: {
                xs: "0vh",
                sm: "10vh",
                mb: "10vh",
                lg: "10vh",
                xl: "10vh",
              },
              paddingLeft: {
                xs: "0vh",
                sm: "10vh",
                mb: "10vh",
                lg: "10vh",
                xl: "10vh",
              },
              paddingBottom: {
                xs: "0vh",
                sm: "10vh",
                mb: "10vh",
                lg: "10vh",
                xl: "10vh",
              },
            }}
          >
            <PromotionalCarousel2></PromotionalCarousel2>
          </Box>
        </Grid>
        <Grid
          sx={{
            textAlign: "center",
            height: "100%",
            backgroundColor: "#556B2F",
            justifyContent: "center",
            alignContent: "center",

            paddingTop: {
              md: "0vh",
              lg: "5vh",
              xl: "10vh",
            },
            paddingBottom: {
              md: "0vh",
              lg: "15vh",
              xl: "15vh",
            },
            paddingLeft: {
              md: "0vh",
              lg: "15vh",
              xl: "15vh",
            },
            paddingRight: {
              md: "0vh",
              lg: "15vh",
              xl: "15vh",
            },
          }}
          xs={12}
          sm={12}
          item
          id="WhyMendoza?"
        >
          <Box
            sx={{
              padding: {
                xs: "5vh",
                sm: "5vh",
                mb: "0vh",
                lg: "0vh",
                xl: "0vh",
              },
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                color: "#E8FCC2",
                fontFamily: "'Playfair Display', serif",
                fontSize: {
                  xs: "2.4rem",
                  sm: "2.4rem",
                  mb: "2rem",
                  lg: "4rem",
                  xl: "4.5rem",
                },
              }}
            >
              Why Mendoza?
            </Typography>

            <Typography
              sx={{
                color: "#fff",

                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  mb: "1rem",
                  lg: "1.5rem",
                  xl: "2rem",
                },
                paddingTop: {
                  xs: "0vh",
                  sm: "0vh",
                  mb: "0vh",
                  lg: "5vh",
                  xl: "5vh",
                },
                paddingRight: {
                  xs: "0vh",
                  sm: "0vh",
                  mb: "0vh",
                  lg: "15vh",
                  xl: "15vh",
                },
                paddingLeft: {
                  xs: "0vh",
                  sm: "0vh",
                  mb: "0vh",
                  lg: "15vh",
                  xl: "15vh",
                },
                paddingBottom: {
                  xs: "0vh",
                  sm: "0vh",
                  mb: "0vh",
                  lg: "0vh",
                  xl: "0vh",
                },
              }}
              color="white"
              variant="body1"
            >
              The wine capital of Argentina offers more than vineyards and views
              of the Andes. Mendoza boasts a dry climate and diverse geography
              with rivers, lakes, and even ski resorts for winter enthusiasts,
              providing a healthy lifestyle in close contact with nature.
              Recognized as an ideal place for retirement and relaxation,
              Mendoza combines excellent wine with some of the world’s finest
              meats. Furthermore, it is a productive and growing center, thanks
              to its strategic location and international connections.
            </Typography>
            <Box
              display={"flex"}
              sx={{
                justifyContent: "space-around",
                paddingTop: {
                  xs: "5vh",
                  sm: "5vh",
                  mb: "5vh",
                  lg: "5vh",
                  xl: "10vh",
                },
              }}
            >
              <Button
                onClick={() => navigate(`/properties`)}
                sx={{
                  color: "#E8FCC2",

                  backgroundColor: "#4E3B31",
                }}
              >
                <Typography
                  sx={{
                    color: "#E8FCC2 ",

                    fontSize: {
                      xs: "1rem",
                      sm: "1rem",
                      mb: "1rem",
                      lg: "1.5rem",
                      xl: "1.6rem",
                    },
                    padding: {
                      xs: "0vh",
                      sm: "0vh",
                      mb: "3vh",
                      lg: "3vh",
                      xl: "3vh",
                    },
                  }}
                >
                  Explore properties
                </Typography>
              </Button>
              <Button
                onClick={handleScroll}
                sx={{
                  backgroundColor: "#4E3B31",
                  paddingRight: {
                    xs: "1vh",
                    sm: "1vh",
                    mb: "1vh",
                    lg: "7.8vh",
                    xl: "7.8vh",
                  },
                  paddingLeft: {
                    xs: "1vh",
                    sm: "1vh",
                    mb: "1vh",
                    lg: "7.8vh",
                    xl: "7.8vh",
                  },
                }}
              >
                <Typography
                  id="cu"
                  sx={{
                    color: "#E8FCC2",

                    fontSize: {
                      xs: "1rem",
                      sm: "1rem",
                      mb: "1rem",
                      lg: "1.5rem",
                      xl: "1.6  rem",
                    },
                    padding: {
                      xs: "0vh",
                      sm: "0vh",
                      mb: "3vh",
                      lg: "3vh",
                      xl: "3vh",
                    },
                  }}
                >
                  Contact Us
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} sm={12} item>
          <Box>
            <div>
              <ContactUs></ContactUs>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomePage;
