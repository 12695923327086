import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import { getData } from "../api/data";

function PromotionsCarousel() {
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1536 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1536, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const data = await getData();
        setProperties(data.data); // Asume que los datos están en "data.data"
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };
    fetchProperties();
  }, []);

  const handleClick = (id) => {
    navigate(`/property/${id}`); // Redirige a la página específica de la propiedad
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={6000}
        keyBoardControl={true}
        showDots={true}
        arrows={true}
        renderButtonGroupOutside={false}
        ssr={true}
        swipeable={true}
        draggable={true}
        shouldResetAutoplay={false}
        focusOnSelect={false}
      >
        {properties.map((property, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              width: "100%",
              height: {
                xs: "40vh",
                sm: "80vh",
                mb: "80vh",
                lg: "70vh",
                xl: "60vh",
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            {/* Imagen de fondo */}
            <img
              src={`https://andesconectionsbackend.onrender.com/${property.images[1]}`}
              alt={`Imagen de ${property.name}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />

            {/* Contenedor de contenido */}
            <Box
              sx={{
                position: "absolute",
                bottom: 16,
                left: 16,
                right: 16,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",

                  flexDirection: {
                    xs: "column",
                  },
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "1rem",
                        sm: "1rem",
                        mb: "2rem",
                        lg: "2rem",
                        xl: "2.5rem",
                      },
                    }}
                  >
                    {property.name || "Sin título"}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "1rem",
                        sm: "1rem",
                        mb: "1.5rem",
                        lg: "1.5rem",
                        xl: "1.8rem",
                      },
                      display: {
                        xs: "flex",
                        sm: "flex",
                        mb: "flex",
                        lg: "none",
                        xl: "none",
                      },
                    }}
                  >
                    {property.description
                      ? `${property.description.slice(0, 50)}${
                          property.description.length > 50 ? "..." : ""
                        }`
                      : "Sin descripción"}
                  </Typography>
                </Box>

                <Typography
                  variant="body2"
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      mb: "none",
                      lg: "flex",
                      xl: "flex",
                    },
                    fontSize: {
                      xs: "1rem",
                      sm: "1rem",
                      mb: "1rem",
                      lg: "1.5rem",
                      xl: "2rem",
                    },
                    color: "#fff",
                  }}
                >
                  {property.description
                    ? `${property.description.slice(0, 150)}${
                        property.description.length > 150 ? "..." : ""
                      }`
                    : "Sin descripción"}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleClick(property._id)}
                    sx={{
                      width: "40%",
                      backgroundColor: "#E8FCC2",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: {
                          xs: "1rem",
                          sm: "1rem",
                          mb: "1rem",
                          lg: "1.5rem",
                          xl: "1.8rem",
                        },
                        fontWeight: "bold",
                      }}
                    >
                      View Details
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
}

export default PromotionsCarousel;
