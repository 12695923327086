import axios from "./axios";

export const getData = (body) => axios.get("/getdata", body);

// Obtener una propiedad específica por id
export const getProperty = (id) => axios.get(`/getdata/${id}`);

export const addProperty = (formData) =>
  axios.post("/addproperty", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updateProperty = (id, formData) =>
  axios.post(`/updateproperty/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteProperty = (body) => axios.post("/deleteproperty", body);
