import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
  Box,
  AppBar,
  Toolbar,
  List,
  ListItemText,
  Modal,
  ListItem,
  ListItemIcon,
  Fade,
  Backdrop,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getProperty } from "../api/data"; // Asegúrate de que este endpoint esté bien definido
import CarouselProperty from "../components/CarouselProperty";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import MapComponent from "../components/MapComponent";
import ContactUsProperty from "../components/ContactUsProperty";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SendIcon from "@mui/icons-material/Send";
export default function PropertyDetail() {
  const navigate = useNavigate();
  const { id } = useParams(); // Desestructuración directa para obtener el ID
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true); // Estado para saber si el componente es visible o no
  const componentRef = useRef(null); // Referencia al componente que vamos a observar
  const [open, setOpen] = useState(false);
  // URLs dinámicas
  const phoneUSA = "+1XXXXXXXXXX"; // Reemplaza con el número de USA
  const phoneARG = "+5492604335339"; // Reemplaza con el número de Argentina
  const email = "andesconnect@gmail.com"; // Reemplaza con el correo

  // Handler para abrir/cerrar el modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const loadProperty = async (id) => {
    setLoading(true);
    try {
      const response = await getProperty(id);
      setProperty(response.data);
    } catch (error) {
      console.error("Error al obtener la propiedad:", error);
    } finally {
      setLoading(false); // Asegura que el estado de carga se actualice incluso si ocurre un error
    }
  };

  useEffect(() => {
    if (id) {
      loadProperty(id);
    }
  }, [id]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (componentRef.current) {
        console.log("Ref finalmente asignado:", componentRef.current); // Depuración

        const observer = new IntersectionObserver(
          ([entry]) => {
            console.log("Is visible:", entry.isIntersecting);
            setIsVisible(entry.isIntersecting);
          },
          {
            root: null,
            threshold: 0.1,
          }
        );

        observer.observe(componentRef.current);

        clearInterval(interval); // Limpia el intervalo cuando el ref esté disponible

        return () => {
          observer.disconnect();
        };
      }
    }, 100); // Verifica cada 100ms si el ref está disponible

    return () => clearInterval(interval);
  }, []);
  const whatsappURL = `https://wa.me/+5492604335339?text=Hello%20I%20am%20interested%20in%20${encodeURIComponent(
    property?.name || "property"
  )}`;

  if (loading) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress size={60} />
      </Grid>
    );
  }

  if (!property) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: "100vh" }}
      >
        <Typography variant="h6">Propiedad no encontrada</Typography>
      </Grid>
    );
  }
  const servicesArray = property.services.split(".");
  const handlercontact = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <Grid
      sx={{
        marginTop: {
          xs: "80px",
          sm: "80px",
          mb: "80px",
          lg: "80px",

          xl: "120px",
        },
      }}
      container
      justifyContent="center"
    >
      <Box sx={{ width: "100%" }} style={{ backgroundColor: "#E8FCC2" }}>
        <Typography
          onClick={() => navigate("/properties")}
          sx={{
            marginLeft: "2vh",
            fontSize: {
              xs: "1rem",
              sm: "1rem",
              mb: "1rem",
              lg: "1rem",
              xl: "1.5rem",
            },
          }}
          padding={1}
        >
          {"<"} All properties
        </Typography>
      </Box>

      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
          }}
        >
          <Box>
            <CarouselProperty property={property} />
          </Box>

          <Box
            sx={{
              backgroundColor: "#E8FCC2",
              paddingRight: {
                xs: "1vh",
                sm: "2vh",
                mb: "2vh",
                lg: "10vh",
                xl: "10vh",
              },
              paddingTop: {
                xs: "2vh",
                sm: "2vh",
                mb: "2vh",
                lg: "5vh",
                xl: "5vh",
              },
              paddingLeft: {
                xs: "2vh",
                sm: "2vh",
                mb: "2vh",
                lg: "10vh",
                xl: "10vh",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "1.4rem",
                  sm: "1.4rem",
                  mb: "1.4rem",
                  lg: "2rem",
                  xl: "2rem",
                },
                display: {
                  xs: "flex",
                  sm: "flex",
                  mb: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              {property.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  mb: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
              style={{
                marginBottom: "1rem",
                alignItems: "center",
              }}
            >
              <LocationOnIcon
                style={{ marginRight: "0.5rem", color: "#00123" }}
              />
              {property.ubidirec}
            </Typography>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  mb: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
              justifyContent={"space-between"}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  marginBottom: "1rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer", // Indica visualmente que es clickeable
                  fontSize: {
                    xs: "1.4rem",
                    sm: "1.4rem",
                    mb: "1.4rem",
                    lg: "2rem",
                    xl: "2rem",
                  },
                }}
              >
                <a
                  href={`${property.location}`} // Ruta externa concatenada
                  target="_blank" // Abre en una nueva pestaña
                  rel="noopener noreferrer" // Seguridad para prevenir acceso al `window.opener`
                  style={{
                    textDecoration: "none",
                    color: "inherit", // Hereda el color del texto para mantener el estilo
                    display: "flex", // Alineación del icono y texto
                    alignItems: "center",
                  }}
                >
                  <MapOutlinedIcon
                    style={{ marginRight: "0.5rem", color: "#3366FF" }}
                  />
                  <Typography
                    style={{
                      fontWeight: "bolder",
                      textDecoration: "underline",
                    }}
                    sx={{
                      fontSize: {
                        xs: "1rem",
                        sm: "1rem",
                        mb: "1rem",
                        lg: "1rem",
                        xl: "1.5rem",
                      },
                    }}
                    color="#3366FF"
                  >
                    Open Maps
                  </Typography>
                </a>
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    mb: "1rem",
                    lg: "1rem",
                    xl: "1.5rem",
                  },
                }}
              >
                Type: {property.zone}
              </Typography>
            </Box>
            <Box
              alignItems={"center"}
              justifyContent={"space-around"}
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  mb: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <Typography
                variant="body2"
                color="#000"
                style={{
                  marginBottom: "1rem",
                  display: "flex",
                  alignItems: "center",
                  aling: "center",
                  cursor: "pointer", // Indica visualmente que es clickeable
                }}
              >
                <Typography sx={{ fontSize: "2.5rem" }} color="#00123">
                  ${property.price}
                </Typography>
              </Typography>
              <Button
                onClick={handleOpen}
                sx={{ backgroundColor: "#4e3b31" }}
                variant="contained"
              >
                <Typography sx={{ color: "#E8FCC2" }}>
                  I'm Interested
                </Typography>
              </Button>
            </Box>
            <Typography
              variant="h5"
              style={{
                marginBottom: ".5rem",
              }}
              sx={{
                fontSize: {
                  xs: "1.4rem",
                  sm: "1.4rem",
                  mb: "1.4rem",
                  lg: "2rem",
                  xl: "2rem",
                },
              }}
            >
              Details
            </Typography>
            <Typography
              style={{
                marginBottom: "1rem",
              }}
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  mb: "1rem",
                  lg: "1.4rem",
                  xl: "1.8rem",
                },
              }}
            >
              {property.description}
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "1.4rem",
                  sm: "1.4rem",
                  mb: "1.4rem",
                  lg: "2rem",
                  xl: "2rem",
                },
              }}
              variant="h5"
              style={{ marginBottom: ".5rem" }}
            >
              Overview
            </Typography>
            {/* Contenedor con Flexbox para crear las dos columnas */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {/* Primera columna con los primeros tres servicios */}
              <Box sx={{ flexBasis: "45%" }}>
                {servicesArray.slice(0, 3).map((service, index) => (
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "1rem",
                        sm: "1rem",
                        mb: "1.4rem",
                        lg: "1.4rem",
                        xl: "2rem",
                      },
                    }}
                    color="black"
                    key={index}
                  >
                    • {service.trim()}
                  </Typography>
                ))}
              </Box>

              {/* Segunda columna con los servicios restantes */}
              <Box sx={{ flexBasis: "45%" }}>
                {servicesArray.slice(3).map((service, index) => (
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "1rem",
                        sm: "1rem",
                        mb: "1.4rem",
                        lg: "1.4rem",
                        xl: "2rem",
                      },
                    }}
                    variant="h6"
                    color="black"
                    key={index + 3}
                  >
                    • {service.trim()}
                  </Typography>
                ))}
              </Box>
              <Typography
                variant="h5"
                style={{ marginBottom: ".5rem" }}
                sx={{
                  fontSize: {
                    xs: "1.4rem",
                    sm: "1.4rem",
                    mb: "1.4rem",
                    lg: "2rem",
                    xl: "2rem",
                  },
                }}
              >
                Payment Options
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    mb: "1rem",
                    lg: "1.4rem",
                    xl: "1.8rem",
                  },
                }}
                variant="body1"
                style={{ marginBottom: "1rem" }}
              >
                {property.payments}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        sx={{
          display: {
            xs: "none",
            sm: "none",
            mb: "none",
            lg: "flex",
            xl: "flex",
          },
        }}
        item
        xs={12}
        sm={4}
        md={4}
        lg={4}
        xl={4}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="communication-modal-title"
          aria-describedby="communication-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 350,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography
              id="communication-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
            >
              Connect with Andes Today
            </Typography>

            {/* Opciones de comunicación */}
            <List>
              <ListItem
                button
                onClick={() => (window.location.href = `tel:${phoneARG}`)}
              >
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText primary="Call Now (ARG Number)" />
              </ListItem>
              <ListItem
                button
                onClick={() => (window.location.href = `tel:${phoneUSA}`)}
              >
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText primary="Call Now (USA Number)" />
              </ListItem>
              <ListItem
                button
                onClick={() => (window.location.href = `mailto:${email}`)}
              >
                <ListItemIcon>
                  <MailOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Mail" />
              </ListItem>
              <ListItem
                button
                onClick={() => (window.location.href = whatsappURL)}
              >
                <ListItemIcon>
                  <WhatsAppIcon />
                </ListItemIcon>
                <ListItemText primary="WhatsApp" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  handlercontact("cu");
                  setOpen(false);
                }}
              >
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText primary="Contact Form" />
              </ListItem>
            </List>

            {/* Botón para cerrar */}
            <Button
              onClick={handleClose}
              sx={{ mt: 2, width: "100%" }}
              variant="contained"
            >
              Close
            </Button>
          </Box>
        </Modal>
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#E8FCC2",
            flexDirection: "column",
          }}
        >
          <Typography
            ref={componentRef}
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                mb: "1rem",
                lg: "1.8rem",
                xl: "3rem",
              },
              textAlign: "center",
              padding: "2vh",
            }}
          >
            {property.name}
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"space-around"}
            alignContent={"center"}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                marginBottom: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <LocationOnIcon
                style={{
                  marginRight: "0.5rem",
                  color: "#00123",
                  paddingLeft: "5vh",
                }}
              />
              {property.ubidirec}
            </Typography>
            <a
              href={`${property.location}`} // Ruta externa concatenada
              target="_blank" // Abre en una nueva pestaña
              rel="noopener noreferrer" // Seguridad para prevenir acceso al `window.opener`
              style={{
                textDecoration: "none",
                color: "inherit", // Hereda el color del texto para mantener el estilo
                display: "flex", // Alineación del icono y texto
                alignItems: "center",
              }}
            >
              <MapOutlinedIcon
                style={{ marginRight: "0.5rem", color: "#3366FF" }}
              />
              <Typography
                style={{
                  fontWeight: "bolder",
                  textDecoration: "underline",
                }}
                sx={{
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    mb: "1rem",
                    lg: "1rem",
                    xl: "1.5rem",
                  },
                }}
                color="#3366FF"
              >
                Open Maps
              </Typography>
            </a>
          </Box>

          <Box sx={{ paddingLeft: "5vh", paddingRight: "5vh" }}>
            <MapComponent locationUrl={property.location} />
          </Box>

          <Box
            alignItems={"center"}
            justifyContent={"space-around"}
            display={"flex"}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                marginBottom: "1rem",
                display: "flex",
                alignItems: "center",
                aling: "center",
                cursor: "pointer", // Indica visualmente que es clickeable
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: {
                    xs: "1vh",
                    sm: "1vh",
                    mb: "1vh",
                    lg: "2vh",
                    xl: "2vh",
                  },
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      sm: "1rem",
                      mb: "1rem",
                      lg: "2rem",
                      xl: "3rem",
                    },
                    marginBottom: "4vh",
                  }}
                  color="#000"
                >
                  USD ${property.price}
                </Typography>
                <Button
                  onClick={handleOpen}
                  sx={{ backgroundColor: "#4e3b31" }}
                  variant="contained"
                >
                  <Typography
                    sx={{
                      color: "#E8FCC2",
                      fontSize: {
                        xs: "1rem",
                        sm: "1rem",
                        mb: "1rem",
                        lg: "1rem",
                        xl: "2rem",
                      },
                    }}
                  >
                    I'm Interested
                  </Typography>
                </Button>
              </Box>
            </Typography>
          </Box>
        </Box>
      </Grid>

      {isVisible ? (
        ""
      ) : (
        <AppBar
          position="fixed"
          sx={{
            display: {
              xs: "none",
              sm: "none",
              mb: "none",
              lg: "flex",
              xl: "flex",
            },
            top: {
              xs: "auto",
              md: "auto",
            },
            bottom: {
              xs: 0,
              md: 0,
            },
            left: {
              xs: 0,
              md: "auto",
            },
            marginRight: {
              xs: 0,
              md: "auto",
              lg: "10px",
            },
            height: {
              mb: "400px",
              lg: "400px",
              xl: "550px",
            },
            width: {
              mb: "400px",
              lg: "450px",
              xl: "550px",
            },
            backgroundColor: "#FFF",
            backdropFilter: {
              xs: "none",
              md: "blur(10px)",
            },
            borderRadius: {
              xs: 0,
              md: "10px",
            },
            justifyContent: "center",
            padding: { xs: "2vh", md: "3vh" },
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              ref={componentRef}
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  mb: "1rem",
                  lg: "1.7rem",
                  xl: "2.2rem",
                },
                textAlign: "center",
              }}
            >
              {property.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  mb: "1rem",
                  lg: "1rem",
                  xl: "2.2rem",
                },
              }}
            >
              <LocationOnIcon
                sx={{
                  marginRight: "0.5rem",
                  color: "#00123",
                }}
              />
              {property.ubidirec}
            </Typography>
            <Box
              sx={{ paddingLeft: "5vh", paddingRight: "5vh", width: "100%" }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                marginTop: "2vh",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  cursor: "pointer",
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    mb: "1rem",
                    lg: "1.5rem",
                    xl: "4rem",
                  },
                  marginBottom: "4vh",
                }}
              >
                USD ${property.price}
              </Typography>
              <Button
                sx={{
                  backgroundColor: "#4e3b31",
                  ":hover": { backgroundColor: "#3e2b21" },
                }}
                variant="contained"
                onClick={handleOpen}
              >
                <Typography
                  sx={{
                    color: "#E8FCC2",
                    fontSize: {
                      xs: "1rem",
                      sm: "1rem",
                      mb: "1rem",
                      lg: "1.5rem",
                      xl: "2rem",
                    },
                  }}
                >
                  I'm Interested
                </Typography>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Grid xs={12} item>
        <Box>
          <div id="cu">
            <ContactUsProperty></ContactUsProperty>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
}
