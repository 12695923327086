import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { getData, addProperty, deleteProperty } from "../api/data";
import { getContactData } from "../api/contacts";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselProperty from "../components/CarouselProperties";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";

function PanelPage() {
  const [property, setProperty] = useState({
    name: "",
    location: "",
    ubizone: "",
    ubidirec: "",
    type: "",
    size: "",
    price: "",
    description: "",
    services: "",
    zone: "",
    payments: "",
    images: [],
  });
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const data = await getData();
        setProperties(data.data);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };
    fetchProperties();
  }, []);

  const handleClick = (id) => {
    navigate(`/property/${id}`); // Redirige a la página específica de la propiedad
  };
  return (
    <Box
      sx={{
        marginTop: {
          xs: "80px",
          sm: "80px",
          mb: "80px",
          lg: "80px",
          xl: "120px",
        },
      }}
      backgroundColor="#E8FCC2"
    >
      <Grid
        sx={{
          backgroundColor: "#4E3B31",
        }}
        container
      >
        <Box
          sx={{
            padding: "2vh",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
          style={{ backgroundColor: "#E8FCC2" }}
        >
          <Typography onClick={() => navigate("/")}>
            {" "}
            {"<"} Back to Home
          </Typography>
          <Typography> All properties</Typography>
        </Box>
        {properties.map((prop) => (
          <Grid
            sx={{
              marginBottom: "2vh",
              marginTop: "2vh",
              paddingRight: {
                xs: "0",
                sm: "0",
                mb: "0",
                lg: "5vh",
                xl: "5vh",
              },
              paddingLeft: {
                xs: "0",
                sm: "0",
                mb: "0",
                lg: "5vh",
                xl: "5vh",
              },
            }}
            item
            xs={12}
            sm={6}
            key={prop._id}
            onClick={() => handleClick(prop._id)}
          >
            <Box>
              <Box>
                <CarouselProperty property={prop} />
              </Box>
              <Box
                sx={{
                  backgroundColor: "#E8FCC2",
                  padding: "2vh",
                  borderBottomRightRadius: "2vh",
                  borderBottomLeftRadius: "2vh",
                }}
              >
                <Typography sx={{ fontSize: "1.5rem" }}>{prop.name}</Typography>

                <Box justifyContent={"space-between"} display={"flex"}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    style={{
                      marginBottom: "1rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LocationOnIcon
                      style={{ marginRight: "0.5rem", color: "#00123" }}
                    />
                    {prop.ubizone}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    style={{
                      marginBottom: "1rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Type: {prop.zone}
                  </Typography>
                </Box>
                <Box
                  alignItems={"center"}
                  justifyContent={"space-around"}
                  display={"flex"}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{
                      marginBottom: "1rem",
                      display: "flex",
                      alignItems: "center",
                      aling: "center",
                      cursor: "pointer", // Indica visualmente que es clickeable
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "1.5rem",
                          sm: "2rem",
                          mb: "2rem",
                          lg: "2rem",
                          xl: "2rem",
                        },
                      }}
                      color="#000"
                    >
                      USD ${prop.price}
                    </Typography>
                  </Typography>
                  <Button
                    sx={{ backgroundColor: "#556B2F" }}
                    variant="contained"
                    onClick={() => handleClick(prop._id)}
                  >
                    <Typography sx={{ color: "#E8FCC2" }}>
                      View details
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default PanelPage;
