import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
  IconButton,
  Grid,
  Divider,
  Box,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import VisaIcon from "@mui/icons-material/CreditCard"; // You may need to import an appropriate icon
import MasterCardIcon from "@mui/icons-material/CreditCard"; // You may need to import an appropriate icon
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useNavigate } from "react-router-dom";
function Footer() {
  const navigate = useNavigate();
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Esto agrega un efecto de desplazamiento suave
    });
  };
  const handleWhatsAppClick = () => {
    window.location.href = "https://wa.me/message/HOYXZTTFNXNZH1";
  };
  const phoneNumber = "+5492604335339"; // Reemplaza con el número que desees
  return (
    <Box
      sx={{
        backgroundColor: "#556B2F ",
        color: "#fff",
        padding: "1vh",
        fontFamily: "Microsoft New Tai Lue",
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={6} md={6} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontSize: {
                xs: "1.8rem",
                sm: "1rem",
                mb: "1rem",
                lg: "1.8rem",
                xl: "3rem",
              },
              color: "#D0F4EA",
              fontFamily: "'Playfair Display', serif",
            }}
          >
            ANDES CONNECTIONS
          </Typography>
          <Box
            sx={{
              paddingTop: {
                xs: "2vh",
                sm: "2vh",
                mb: "2vh",
                lg: "2vh",
                xl: "2vh",
              },
              paddingBottom: {
                xs: "2vh",
                sm: "2vh",
                mb: "2vh",
                lg: "2vh",
                xl: "2vh",
              },
            }}
          >
            <IconButton
              href="https://www.facebook.com"
              target="_blank"
              color="#D0F4EA"
            >
              <FacebookIcon
                sx={{
                  fontSize: {
                    xs: "1.8rem",
                    sm: "1rem",
                    mb: "1rem",
                    lg: "1.8rem",
                    xl: "4rem",
                  },
                  color: "#D0F4EA",
                }}
              />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/andesconnections/"
              target="_blank"
              color="#D0F4EA"
            >
              <InstagramIcon
                sx={{
                  fontSize: {
                    xs: "1.8rem",
                    sm: "1rem",
                    mb: "1rem",
                    lg: "1.8rem",
                    xl: "4rem",
                  },
                  color: "#D0F4EA",
                }}
              />
            </IconButton>

            <IconButton
              href="https://wa.me/+5492604335339?text=${encodeURIComponent(
    `Estoy interesado en la propiedad ${property.name}. Me gustaría obtener más información."
            >
              <WhatsAppIcon
                sx={{
                  fontSize: {
                    xs: "1.8rem",
                    sm: "1rem",
                    mb: "1rem",
                    lg: "1.8rem",
                    xl: "4rem",
                  },
                  color: "#D0F4EA",
                }}
              />
            </IconButton>
            <IconButton
              href="https://www.linkedin.com/in/tu-perfil" // Reemplaza con tu URL de LinkedIn
              target="_blank"
            >
              <LinkedInIcon
                sx={{
                  fontSize: {
                    xs: "1.8rem",
                    sm: "1rem",
                    mb: "1rem",
                    lg: "1.8rem",
                    xl: "4rem",
                  },
                  color: "#D0F4EA",
                }}
              />
            </IconButton>
          </Box>
          <Divider
            sx={{
              marginRight: {
                xs: "0vh",
                sm: "0vh",
                mb: "0vh",
                lg: "20vh",
                xl: "20vh",
              },
              marginLeft: {
                xs: "0vh",
                sm: "0vh",
                mb: "0vh",
                lg: "20vh",
                xl: "20vh",
              },
            }}
            color="#D0F4EA"
          ></Divider>
          <Typography
            sx={{
              fontSize: {
                xs: "1.2rem",
                sm: "1rem",
                mb: "1rem",
                lg: "1.8rem",
                xl: "3rem",
              },
              paddingTop: {
                xs: "2vh",
                sm: "2vh",
                mb: "2vh",
                lg: "2vh",
                xl: "2vh",
              },
              paddingBottom: {
                xs: "2vh",
                sm: "2vh",
                mb: "2vh",
                lg: "2vh",
                xl: "2vh",
              },
              color: "#D0F4EA",
              fontFamily: "'Playfair Display', serif",
            }}
          >
            Customer Services
          </Typography>

          <Typography
            onClick={() => (window.location.href = `tel:${phoneNumber}`)}
            sx={{
              margin: "1vh",
              padding: "2vh",

              fontSize: {
                xs: "1rem",
                sm: "1rem",
                mb: "1rem",
                lg: "1.2rem",
                xl: "2rem",
              },
              color: "#D0F4EA",
            }}
          >
            {phoneNumber} Mendoza, Argentina
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ textAlign: "center" }}>
          <Button
            sx={{
              width: "100%",
              marginTop: {
                xs: "2vh",
                sm: "2vh",
                mb: "2vh",
                lg: "2vh",
                xl: "2vh",
              },
              marginBottom: {
                xs: "2vh",
                sm: "2vh",
                mb: "2vh",
                lg: "2vh",
                xl: "2vh",
              },
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                mb: "1rem",
                lg: "1.3rem",
                xl: "2rem",
              },
              color: "#D0F4EA",
            }}
            variant="outlined"
            color="primary"
            onClick={() => navigate("/properties")}
          >
            Properties
          </Button>

          <Button
            sx={{
              width: "100%",
              marginTop: {
                xs: "2vh",
                sm: "2vh",
                mb: "2vh",
                lg: "2vh",
                xl: "2vh",
              },
              marginBottom: {
                xs: "2vh",
                sm: "2vh",
                mb: "2vh",
                lg: "2vh",
                xl: "2vh",
              },
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                mb: "1rem",
                lg: "1.3rem",
                xl: "2rem",
              },
              color: "#D0F4EA",
            }}
            variant="outlined"
            color="primary"
            onClick={handleScrollToTop}
          >
            Back to top
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ margin: "2vh", backgroundColor: "white" }} />
      <Link
        href="https://webprofitdesign.com/"
        color="secondary"
        underline="none"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography
          variant="body2"
          align="center"
          sx={{
            color: "#D0F4EA",

            paddingBottom: {
              xs: "2vh",
              sm: "2vh",
              mb: "2vh",
              lg: "2vh",
              xl: "2vh",
            },
            fontSize: {
              xs: "1rem",
              sm: "1rem",
              mb: "1rem",
              lg: "1rem",
              xl: "2rem",
            },
          }}
        >
          &copy; 2024 Powered By Webprofit
        </Typography>
      </Link>
    </Box>
  );
}

export default Footer;
