import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import heroBanner1 from "../images/heroBanner1.webp";
import heroBanner2 from "../images/heroBanner2.webp";
import heroBanner3 from "../images/heroBanner3.webp";
import heroBanner4 from "../images/heroBanner4.webp";

function PromotionsCarousel() {
  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1536 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1536, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const imageUrls = [heroBanner1, heroBanner2, heroBanner3, heroBanner4];

  const handleClick = () => {
    navigate("/properties");
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: {
          xs: "40vh",
          sm: "40vh",
          mb: "60vh",
          lg: "60vh",
          xl: "60vh",
        },
      }}
    >
      {/* Contenido estático sobre el carrusel */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          zIndex: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "1.2rem",
              sm: "1.4rem",
              mb: "1.4rem",
              lg: "2rem",
              xl: "3rem",
            },
            color: "#fff",
            textShadow: "5px 2px 4px rgba(0, 0, 0, 05)",
          }}
        >
          Get your house in the heart of the Andes
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            backgroundColor: "#4E3B31",
            fontWeight: "bold",

            mt: 2,
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={handleClick}
        >
          <Typography
            sx={{
              color: "#D0F4EA",

              fontSize: {
                xs: "1rem",
                sm: "1rem",
                mb: "1rem",
                lg: "1.5rem",
                xl: "2rem",
              },
            }}
          >
            Learn More{" "}
          </Typography>
        </Button>
      </Box>

      {/* Carrusel de imágenes en el fondo */}
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={10000}
        keyBoardControl={true}
        showDots={false}
        arrows={false}
        renderButtonGroupOutside={false}
        ssr={true}
        swipeable={false}
        draggable={false}
        shouldResetAutoplay={false}
        focusOnSelect={false}
        containerClass="carousel-container"
        itemClass="carousel-item"
      >
        {imageUrls.map((url, index) => (
          <Box
            key={index}
            sx={{
              width: "100%",
              height: "80vh",
              height: {
                xs: "40vh",
                sm: "40vh",
                mb: "60vh",
                lg: "60vh",
                xl: "60vh",
              },
              backgroundImage: `url(${url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        ))}
      </Carousel>
    </Box>
  );
}

export default PromotionsCarousel;
