import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import contactimage from "../images/contact.webp";
import { submitContactForm } from "../api/contacts"; // Importa la función de envío
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function ContactUs() {
  const [successMessage, setSuccessMessage] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    country: "",
    phoneNumber: "",
    preferredContactTime: "",
    preferredContactMethod: "",
    propertiesOfInterest: "",
    message: "",
    agreeToReceive: false,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Si el campo es 'country', actualizamos el país y el número de teléfono
    if (name === "country") {
      const selectedCountry = countries.find(
        (country) => country.name === value
      );
      setFormData((prevData) => ({
        ...prevData,
        country: value,
        phoneNumber: selectedCountry
          ? selectedCountry.code
          : prevData.phoneNumber, // Mantiene el código del país seleccionado
      }));
    } else {
      // Si el campo no es 'country', simplemente actualiza el valor de 'name' de acuerdo al tipo
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Previene el comportamiento por defecto del formulario
    try {
      console.log(formData);
      const response = await submitContactForm(formData); // Llama a la función de la API
      setSuccessMessage(true);
      // Aquí puedes agregar una acción posterior, como mostrar un mensaje de éxito
    } catch (error) {
      console.error("Error submitting form:", error);
      // Maneja el error, por ejemplo, mostrando un mensaje de error
    }
  };

  const countries = [
    { name: "Albania", code: "+355" },
    { name: "Algeria", code: "+213" },
    { name: "Andorra", code: "+376" },
    { name: "Antigua and Barbuda", code: "+1-268" },
    { name: "Argentina", code: "+54" },
    { name: "Australia", code: "+61" },
    { name: "Austria", code: "+43" },
    { name: "Azerbaijan", code: "+994" },
    { name: "Bahamas", code: "+1-242" },
    { name: "Belarus", code: "+375" },
    { name: "Belgium", code: "+32" },
    { name: "Belize", code: "+501" },
    { name: "Bolivia", code: "+591" },
    { name: "Bosnia and Herzegovina", code: "+387" },
    { name: "Brazil", code: "+55" },
    { name: "Bulgaria", code: "+359" },
    { name: "Cameroon", code: "+237" },
    { name: "Canada", code: "+1" },
    { name: "Chile", code: "+56" },
    { name: "China", code: "+86" },
    { name: "Colombia", code: "+57" },
    { name: "Costa Rica", code: "+506" },
    { name: "Croatia", code: "+385" },
    { name: "Cuba", code: "+53" },
    { name: "Cyprus", code: "+357" },
    { name: "Czech Republic", code: "+420" },
    { name: "Denmark", code: "+45" },
    { name: "Djibouti", code: "+253" },
    { name: "Dominica", code: "+1-767" },
    { name: "Dominican Republic", code: "+1-809" },
    { name: "Ecuador", code: "+593" },
    { name: "Egypt", code: "+20" },
    { name: "El Salvador", code: "+503" },
    { name: "Estonia", code: "+372" },
    { name: "Fiji", code: "+679" },
    { name: "Finland", code: "+358" },
    { name: "France", code: "+33" },
    { name: "Gabon", code: "+241" },
    { name: "Gambia", code: "+220" },
    { name: "Georgia", code: "+995" },
    { name: "Germany", code: "+49" },
    { name: "Greece", code: "+30" },
    { name: "Guatemala", code: "+502" },
    { name: "Honduras", code: "+504" },
    { name: "Hungary", code: "+36" },
    { name: "Iceland", code: "+354" },
    { name: "India", code: "+91" },
    { name: "Indonesia", code: "+62" },
    { name: "Ireland", code: "+353" },
    { name: "Israel", code: "+972" },
    { name: "Italy", code: "+39" },
    { name: "Jamaica", code: "+1-876" },
    { name: "Japan", code: "+81" },
    { name: "Jordan", code: "+962" },
    { name: "Korea (North)", code: "+850" },
    { name: "Korea (South)", code: "+82" },
    { name: "Kyrgyzstan", code: "+996" },
    { name: "Latvia", code: "+371" },
    { name: "Lebanon", code: "+961" },
    { name: "Liechtenstein", code: "+423" },
    { name: "Lithuania", code: "+370" },
    { name: "Luxembourg", code: "+352" },
    { name: "Madagascar", code: "+261" },
    { name: "Malaysia", code: "+60" },
    { name: "Maldives", code: "+960" },
    { name: "Mexico", code: "+52" },
    { name: "Micronesia", code: "+691" },
    { name: "Moldova", code: "+373" },
    { name: "Monaco", code: "+377" },
    { name: "Montenegro", code: "+382" },
    { name: "Netherlands", code: "+31" },
    { name: "New Zealand", code: "+64" },
    { name: "Nicaragua", code: "+505" },
    { name: "North Macedonia", code: "+389" },
    { name: "Norway", code: "+47" },
    { name: "Panama", code: "+507" },
    { name: "Paraguay", code: "+595" },
    { name: "Peru", code: "+51" },
    { name: "Philippines", code: "+63" },
    { name: "Poland", code: "+48" },
    { name: "Portugal", code: "+351" },
    { name: "Qatar", code: "+974" },
    { name: "Romania", code: "+40" },
    { name: "Russia", code: "+7" },
    { name: "Saudi Arabia", code: "+966" },
    { name: "Serbia", code: "+381" },
    { name: "Singapore", code: "+65" },
    { name: "Slovakia", code: "+421" },
    { name: "Slovenia", code: "+386" },
    { name: "South Africa", code: "+27" },
    { name: "Spain", code: "+34" },
    { name: "Sweden", code: "+46" },
    { name: "Switzerland", code: "+41" },
    { name: "Taiwan", code: "+886" },
    { name: "Thailand", code: "+66" },
    { name: "Turkey", code: "+90" },
    { name: "Ukraine", code: "+380" },
    { name: "United Arab Emirates", code: "+971" },
    { name: "United Kingdom", code: "+44" },
    { name: "United States", code: "+1" },
    { name: "Uruguay", code: "+598" },
    { name: "Venezuela", code: "+58" },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#4E3B31",
      }}
    >
      <Snackbar
        open={successMessage}
        autoHideDuration={10000} // El mensaje se ocultará automáticamente después de 6 segundos
        onClose={() => setSuccessMessage(false)} // Cierra el Snackbar
      >
        <Alert
          onClose={() => setSuccessMessage(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Your message has been sent successfully!
        </Alert>
      </Snackbar>
      {/* Header Section */}
      <Box
        sx={{
          textAlign: "center",
          paddingTop: {
            md: "0vh",
            lg: "5vh",
            xl: "10vh",
          },
          paddingBottom: {
            md: "0vh",
            lg: "15vh",
            xl: "15vh",
          },
          paddingLeft: {
            md: "0vh",
            lg: "15vh",
            xl: "15vh",
          },
          paddingRight: {
            md: "0vh",
            lg: "15vh",
            xl: "15vh",
          },
          padding: {
            xs: "3vh",
            sm: "3vh",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "'Playfair Display', serif",
            fontSize: {
              xs: "3rem",
              sm: "3rem",
              mb: "2rem",
              lg: "4rem",
              xl: "4.5rem",
            },
          }}
          color="white"
          variant="h4"
          component="h1"
          gutterBottom
        >
          Contact Us
        </Typography>
        <Typography
          color="white"
          sx={{
            fontSize: {
              xs: "1rem",
              sm: "1rem",
              mb: "1rem",
              lg: "1.5rem",
              xl: "2rem",
            },
            paddingTop: {
              xs: "0vh",
              sm: "0vh",
              mb: "0vh",
              lg: "5vh",
              xl: "5vh",
            },
            paddingRight: {
              xs: "0vh",
              sm: "0vh",
              mb: "0vh",
              lg: "15vh",
              xl: "15vh",
            },
            paddingLeft: {
              xs: "0vh",
              sm: "0vh",
              mb: "0vh",
              lg: "15vh",
              xl: "15vh",
            },
            paddingBottom: {
              xs: "0vh",
              sm: "0vh",
              mb: "0vh",
              lg: "0vh",
              xl: "0vh",
            },
          }}
        >
          Exploring a distant country can be challenging. Our team is here to
          guide you through every step of the process and help you succeed in
          the South American market, so you can find your ideal property. Please
          leave your contact information below.
        </Typography>
      </Box>

      {/* Form Section */}
      <Box
        component="form"
        onSubmit={handleSubmit} // Asocia el submit con el handler
        sx={{
          backgroundImage: `url(${contactimage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingLeft: "40px",
          paddingBottom: "40px",
          paddingRight: "40px",
          paddingTop: "5px",
          maxWidth: "100%",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box
          sx={{
            paddingBottom: {
              md: "0vh",
              lg: "15vh",
              xl: "5vh",
            },
            paddingLeft: {
              md: "0vh",
              lg: "10vh",
              xl: "30vh",
            },
            paddingRight: {
              md: "0vh",
              lg: "70vh",
              xl: "30vh",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                mb: "1rem",
                lg: "4rem",
                xl: "4rem",
              },
              fontWeight: "bold",
              textAlign: "center",
              margin: "20px",
            }}
          >
            Your Details
          </Typography>

          {/* Individual TextFields with Box Wrappers */}
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              padding: "10px",
              marginBottom: "16px",
            }}
          >
            <TextField
              fullWidth
              label="Full Name"
              variant="outlined"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "8px",
                  },
                },
              }}
            />
          </Box>

          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              padding: "10px",
              marginBottom: "16px",
            }}
          >
            <TextField
              fullWidth
              label="Email"
              type="email"
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "8px",
                  },
                },
              }}
            />
          </Box>
          <Box gap={2} display={"flex"}>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                padding: "10px",
                marginBottom: "16px",
              }}
            >
              <Autocomplete
                disablePortal
                id="country-select"
                options={countries}
                value={formData.country} // Si formData.country es undefined, mostrar "Country"
                onChange={(event, newValue) => {
                  handleChange({
                    target: {
                      name: "country",
                      value: newValue ? newValue.name : "Country", // Asegúrate de enviar solo el nombre del país o "Country"
                    },
                  });
                }}
                filterOptions={(options, state) => {
                  // Filtrar las opciones basándonos en el input del usuario
                  return options.filter((option) =>
                    option.name
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px",
                        },
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.name}>
                    {option?.name || "Country"}{" "}
                    {/* Si option.name es undefined, mostrar "Country" */}
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value?.name
                } // Asegura la comparación correcta
                sx={{
                  width: "100%",
                }}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                padding: "10px",
                marginBottom: "16px",
              }}
            >
              <TextField
                fullWidth
                label="Phone Number"
                type="tel"
                variant="outlined"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              padding: "10px",
              marginBottom: "16px",
            }}
          >
            <FormControl fullWidth>
              <InputLabel>Properties of Interest</InputLabel>
              <Select
                name="propertiesOfInterest"
                value={formData.propertiesOfInterest}
                onChange={handleChange}
              >
                <MenuItem value="Production">Production</MenuItem>
                <MenuItem value="Investment">Investment</MenuItem>
                <MenuItem value="Residential">Residential</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box justifyContent={"space-evenly"} display={"flex"} gap={2}>
            {/* Drop-down Menus */}
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                padding: "10px",
                marginBottom: "16px",
                width: "100%",
              }}
            >
              <FormControl fullWidth margin="normal">
                <InputLabel>Preferred Contact Time</InputLabel>
                <Select
                  name="preferredContactTime"
                  value={formData.preferredContactTime}
                  onChange={handleChange}
                >
                  <MenuItem value="Morning">Morning</MenuItem>
                  <MenuItem value="Afternoon">Afternoon</MenuItem>
                  <MenuItem value="Evening">Evening</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                padding: "10px",
                marginBottom: "16px",
                width: "100%",
              }}
            >
              <FormControl fullWidth margin="normal">
                <InputLabel>Preferred Contact Method</InputLabel>
                <Select
                  name="preferredContactMethod"
                  value={formData.preferredContactMethod}
                  onChange={handleChange}
                >
                  <MenuItem value="Email">Email</MenuItem>
                  <MenuItem value="Phone">Phone</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              padding: "10px",
              marginBottom: "16px",
            }}
          >
            <TextField
              fullWidth
              label="Message"
              multiline
              rows={4}
              variant="outlined"
              name="message"
              value={formData.message}
              onChange={handleChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "8px",
                  },
                },
              }}
            />
          </Box>

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "#fff", // Color para el estado desmarcado
                    "&.Mui-checked": {
                      color: "black", // Color para el estado marcado (tilde negra)
                    },
                  }}
                  name="agreeToReceive"
                  checked={formData.agreeToReceive}
                  onChange={handleChange}
                />
              }
              label="I agree to receive other communications from Andes Connections"
              sx={{
                color: "#fff", // Cambiar color del label
                textShadow: "3px 3px 3px rgba(0, 0, 0, 05)", // Sombra al texto del label
              }}
            />
          </Box>

          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              padding: "10px",
              marginBottom: "16px",
            }}
          >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                !formData.fullName ||
                !formData.email ||
                !formData.country ||
                !formData.phoneNumber ||
                !formData.preferredContactTime ||
                !formData.preferredContactMethod ||
                !formData.propertiesOfInterest ||
                !formData.message
              }
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ContactUs;
