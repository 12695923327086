import React from "react";
import { Box } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function PromotionsCarousel({ property }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1536 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1536, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Box sx={{ width: "100%", height: "30vh" }}>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={6000}
        keyBoardControl={true}
        showDots={true}
        arrows={false}
        ssr={true}
        swipeable={true}
        draggable={true}
      >
        {property.images.map((image, index) => (
          <Box
            key={index}
            sx={{
              width: "100%",
              height: "100%",
              overflow: "hidden", // Para evitar que el contenido se desborde
              borderTopRightRadius: "2vh",
              borderTopLeftRadius: "2vh",
            }}
          >
            <img
              src={`https://andesconectionsbackend.onrender.com/${image}`}
              alt={`Imagen ${index + 1} de ${property.name}`}
              style={{
                width: "100%",
                height: "30vh",
                objectFit: "cover", // Recorta para que se ajuste al contenedor
              }}
            />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
}

export default PromotionsCarousel;
