import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Divider,
  ListItemIcon,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import "../styles/navbar.css";
import Logo from "../images/logoextendedsvg2.svg";
import LanguageIcon from "@mui/icons-material/Language";
function Navbar({ setSearchbarVisible, setSelectedCategory }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false); // Estado del drawer
  const { user, isAuthenticated, logout } = useAuth();
  const [showNavBar, setShowNavBar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  let firstChar = "";
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleDrawerToggleCart = () => {
    navigate("/pay");
  };

  const handleloginpage = () => {
    navigate("/login");
  };
  const handleregisterpage = () => {
    navigate("/register");
  };
  const handlehomepage = () => {
    navigate("/");
  };
  if (isAuthenticated) {
    if (user && user.username && user.lastname) {
      firstChar = user.username.charAt(0).toUpperCase();
      firstChar += user.lastname.charAt(0).toUpperCase();
    } else {
      // Manejar el caso en que user, username o lastname no estén definidos
      firstChar = ""; // O alguna otra lógica por defecto
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOrders = () => {
    navigate("/orders");
  };

  const handleLogout = async () => {
    console.log();
    logout();
  };
  const handleScrollW = () => {
    const element = document.getElementById("WhyMendoza?");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Hace scroll suave al elemento
    }
  };
  const handleScrollC = () => {
    const element = document.getElementById("cu");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Hace scroll suave al elemento
    }
  };
  const handleAccountClick = () => {
    if (isAuthenticated) {
      setDrawerOpen(true);
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Muestra la barra si se desliza hacia arriba, escóndela si es hacia abajo
      if (currentScrollY > lastScrollY) {
        setShowNavBar(false); // Ocultar en scroll hacia abajo
      } else {
        setShowNavBar(true); // Mostrar en scroll hacia arriba
      }

      setLastScrollY(currentScrollY); // Actualizar la posición del scroll
    };

    // Asignar el listener del evento
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Limpiar el listener
    };
  }, [lastScrollY]);

  const drawer = (
    <Box
      sx={{
        backgroundColor: "#556B2F", // Verde
        color: "#D0F4EA", // Celeste para texto
        height: "100%",
        padding: "1vh",
      }}
      onClick={handleDrawerToggle}
    >
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          color: "#E8FCC2", // Beige para destacar
          padding: "2vh 0",
        }}
      >
        Andes Connections
      </Typography>
      <Divider
        sx={{ borderColor: "#4E3B31" /* Marrón */, marginBottom: "1vh" }}
      />

      <List>
        <ListItem button onClick={() => navigate("/properties")}>
          <ListItemText
            primary="Properties"
            primaryTypographyProps={{
              sx: { fontSize: "1.2rem", fontWeight: "bold", color: "#D0F4EA" }, // Celeste
            }}
          />
        </ListItem>
        <Divider sx={{ borderColor: "#4E3B31" }} />

        <ListItem button onClick={handleScrollW}>
          <ListItemText
            primary="Why Mendoza?"
            primaryTypographyProps={{
              sx: { fontSize: "1.2rem", fontWeight: "bold", color: "#D0F4EA" },
            }}
          />
        </ListItem>
        <Divider sx={{ borderColor: "#4E3B31" }} />

        <ListItem button onClick={handleScrollC}>
          <ListItemText
            primary="Contact Us"
            primaryTypographyProps={{
              sx: { fontSize: "1.2rem", fontWeight: "bold", color: "#D0F4EA" },
            }}
          />
        </ListItem>
        <Divider sx={{ borderColor: "#4E3B31" }} />
      </List>

      <Divider sx={{ borderColor: "#4E3B31", marginTop: "2vh" }} />

      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: "#E8FCC2" /* Beige */ }}
        >
          Contact Us
        </Typography>
        <Typography variant="body1" sx={{ color: "#D0F4EA" /* Celeste */ }}>
          +54 9 2604 335339
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        className="appbar"
        position="fixed"
        sx={{
          height: {
            xs: "80px",
            sm: "80px",
            mb: "80px",
            lg: "80px",
            xl: "120px",
          },
          backgroundColor: "#556B2F",
          justifyContent: "center",
        }}
      >
        <Toolbar
          style={{ background: "#556B2F" }}
          sx={{
            display: "flex",
          }}
        >
          <Link
            to="/"
            style={{
              fontWeight: "bold",
              textDecoration: "none",
              color: "#556B2F",
              fontFamily: "'Playfair Display', Arial, sans-serif",
            }}
          >
            <Box
              sx={{
                textAlign: "left",
                paddingLeft: {
                  xs: "0vh",
                  sm: "0vh",
                  mb: "0vh",
                  lg: "0vh",
                  xl: "5vh",
                },
                display: {
                  xs: "none",
                  sm: "none",
                  mb: "none",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <img
                src={Logo}
                alt="Logo"
                style={{
                  width: "70vh",
                  height: "8vh",
                }}
              />
            </Box>
            <Box
              sx={{
                textAlign: "left",

                display: {
                  xs: "flex",
                  sm: "flex",
                  mb: "flex",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <img
                src={Logo}
                alt="Logo"
                style={{
                  width: "80%",
                  height: "10vh",
                }}
              />
            </Box>
          </Link>

          <Box
            sx={{
              justifyContent: "flex-end",
              width: {
                xs: "0%",
                sm: "0%",
                mb: "100%",
                lg: "100%",
                xl: "100%",
              },
            }}
            display={"flex"}
          >
            <Box
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  mb: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <Button
                sx={{
                  display: {
                    xs: "none",
                    sm: "flex",
                    mb: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    mb: "1rem",
                    lg: "1rem",
                    xl: "1.5rem",
                  },
                  marginLeft: {
                    xs: "1vh",
                  },
                  marginRight: {
                    xs: "1vh",
                  },
                  color: "#D0F4EA",
                }}
                edge="end"
                aria-label="search"
                onClick={() => navigate("/properties")}
              >
                Properties
              </Button>

              <Button
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    mb: "none",
                    lg: "flex",
                    xl: "flex",
                  },
                  marginLeft: {
                    xs: "1vh",
                  },
                  marginRight: {
                    xs: "1vh",
                  },
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    mb: "1rem",
                    lg: "1rem",
                    xl: "1.5rem",
                  },
                  color: "#D0F4EA",
                }}
                edge="end"
                aria-label="search"
                href="#"
                onClick={handleScrollW}
              >
                Why Mendoza?
              </Button>

              <Button
                sx={{
                  display: {
                    xs: "none",
                    sm: "flex",
                    mb: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                  marginLeft: {
                    xs: "1vh",
                  },
                  marginRight: {
                    xs: "1vh",
                  },
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    mb: "1rem",
                    lg: "1rem",
                    xl: "1.5rem",
                  },
                  color: "#D0F4EA",
                }}
                edge="end"
                color="primary"
                aria-label="search"
                href="#"
                onClick={handleScrollC}
              >
                Contact Us
              </Button>
            </Box>
          </Box>

          <Box
            border={1}
            borderColor={"#D0F4EA"}
            borderRadius={4}
            sx={{
              display: {
                xs: "flex",
                sm: "none",
                mb: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <IconButton
              aria-label="menu"
              sx={{
                color: "#D0F4EA ",
                display: {
                  xs: "flex",
                  sm: "none",
                  mb: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          anchor="right"
          sx={{
            backgroundColor: "#D0F4EA",
            display: { xs: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: "300px" },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Navbar;
