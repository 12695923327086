import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

document.addEventListener("DOMContentLoaded", () => {
  // Oculta y elimina cualquier banner
  const translateFrame = document.querySelector(".goog-te-banner-frame");
  if (translateFrame) {
    translateFrame.remove();
  }

  // Corrige márgenes dinámicos
  const observer = new MutationObserver(() => {
    const bodyStyle = getComputedStyle(document.body);
    if (bodyStyle.marginTop !== "0px") {
      document.body.style.marginTop = "0px";
    }
  });

  observer.observe(document.body, {
    attributes: true,
    attributeFilter: ["style"],
  });
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
