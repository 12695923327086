import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const MapComponent = ({ locationUrl }) => {
  const [coordinates, setCoordinates] = useState(null);

  // Función para extraer las coordenadas de la URL de Google Maps
  const extractCoordinates = (url) => {
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = url.match(regex);
    if (match) {
      return {
        lat: parseFloat(match[1]),
        lng: parseFloat(match[2]),
      };
    }
    return null;
  };

  useEffect(() => {
    if (locationUrl) {
      const coords = extractCoordinates(locationUrl);
      if (coords) {
        setCoordinates(coords);
      }
    }
  }, [locationUrl]);

  useEffect(() => {
    if (coordinates) {
      // Inicializamos el mapa solo si las coordenadas están disponibles
      const map = L.map("map", {
        center: [coordinates.lat, coordinates.lng],
        zoom: 13,
      });

      // Usamos OpenStreetMap como capa base
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
        map
      );

      // Marcamos la ubicación
      L.marker([coordinates.lat, coordinates.lng])
        .addTo(map)
        .bindPopup("<b>Ubicación</b>")
        .openPopup();

      // Limpieza: eliminar el mapa al desmontar el componente
      return () => {
        map.remove();
      };
    }
  }, [coordinates]);

  return (
    <Box
      sx={{
        position: "relative",
        height: "200px", // Establece una altura específica para el mapa
        height: {
          xs: "200px",
          sm: "200px",
          mb: "200px",
          lg: "200px",
          xl: "400px",
        },
        width: "100%", // Asegúrate de que el contenedor ocupe todo el ancho
        border: "1px solid #ccc",
        borderRadius: "8px",
      }}
    >
      {/* Contenedor del mapa */}
      <div id="map" style={{ height: "100%", width: "100%" }}></div>
    </Box>
  );
};

export default MapComponent;
